export default {
    "props": {
        "logoType": "logoSecondary",
        "dialogTitleProps": {
            "variant": "h5",
            "component": "h2",
        },
        "dialogContentProps": {
            "dialogContentTitleProps": {
                "variant": "body2",
                "color": "textPrimary",
            },
            "dialogSocialButtonProps": {
                "variant": "outlined",
                "color": "primary",
            },
            "dialogSocialButtonGoogleProps": {

            },
            "dialogSocialButtonFBProps": {
                "style": { "color": "#4267B2" }
            },
            "dialogSeparatorProps": {
                "align": "center",
                "component": "div",
            },
            "dialogSeparatorSpanProps": {
                "component": "span",
                "variant": "caption",
            },
            "dialogFormControlProps": {
                "variant": "outlined",
                "fullWidth": true,
                "size": "small",
            },
            "dialogTermsProps": {
                "component": "div",
                "variant": "caption",
            },
            "dialogHelperTextProps": {
                "component": "div",
            },
            "dialogActionsBtnProps": {
                "color": "primary",
                "variant": "contained",
            },
            "dialogCancelActionBtnProps": {
                "color": "secondary",
                "variant": "contained",
            },
        },
        "showMarketingConsent": true,
        "login": {
            "loginBtnType": "btnIcon", // btn, btnIcon, btnTextIcon - default is text only
            // fullScreen - at which breakpoint do we want to convert the modal to full width
            // to disable comment it out
            // "loginBtnVariant": "contained",
            // "fullScreen": "md",
            "dialogScroll": "body",
            "data": {
                "setOpenDefault": true,
                "triggerText": "Login",
                "loginTitle": "Login to your account",
                "loginSubtitle": "Don’t have an account yet?",
                "loginUrl": "/login",
                "loginUrlLabel": "Login",
                "showSocialLogin": true,
                "resetPassLabel": "Forgot your password?",
                "resetPassUrl": "/forgot-password",
                "showTerms": true,
                "termsText": "By clicking “Continue” you agree to our",
                "termsLinkProps": {
                    "color": "inherit",
                    "target": "_blank",
                },
                "termsLink": "",
                "termsLabel": "Terms of Service",
                "privacyLink": "",
                "privacyLabel": "Privacy Policy",
                "formActionLabel": "Continue",
            },
        },
        "signup": {
            "signupBtnType": "btnIcon", // btn, btnIcon, btnTextIcon - default is text only
            // fullScreen - at which breakpoint do we want to convert the modal to full width
            // to disable comment it out
            // "signupBtnVariant": "contained",
            // "fullScreen": "md",
            "dialogScroll": "body",
            "defaultCountry": "GB",
            "usePhoneField": false,
            "data": {
                "setOpenDefault": true,
                "triggerText": "Sign Up",
                "signUpTitle": "Sign up with social media",
                "signUpSubtitle": "Already have an account?",
                "signUpUrl": "/signup",
                "signUpUrlLabel": "Sign Up",
                "showSocialLogin": true,
                "showTerms": true,
                "termsText": "By clicking “Continue” you agree to our",
                "termsLinkProps": {
                    "color": "inherit",
                    "target": "_blank",
                },
                "termsLink": "",
                "termsLabel": "Terms of Service",
                "privacyLink": "",
                "privacyLabel": "Privacy Policy",
                "formActionLabel": "Continue",
            }
        },
        "resetPassEmail": {
            "loginBtnType": "btnIcon", // btn, btnIcon, btnTextIcon - default is text only
            // fullScreen - at which breakpoint do we want to convert the modal to full width
            // to disable comment it out
            // "loginBtnVariant": "contained",
            // "fullScreen": "md",
            "data": {
                "setOpenDefault": true,
                "triggerText": "Reset Password",
                "loginTitle": "Forgot Password",
                "loginSubtitle": "Please enter the email address that you used when creating your account. An email will be sent to that address with instructions on how to reset your password.",
                // "loginUrl": "/signup",
                // "loginUrlLabel": "Reset Password",
                "showSocialLogin": false,
                "showTerms": true,
                "termsText": "By clicking Reset Password you agree to our",
                "termsLinkProps": {
                    "color": "inherit",
                    "target": "_blank",
                },
                "termsLink": "",
                "termsLabel": "Terms of Service",
                "privacyLink": "",
                "privacyLabel": "Privacy Policy",
                "formActionLabel": "Reset Password",
            }
        },
        "resetPass": {
            "loginBtnType": "btnIcon", // btn, btnIcon, btnTextIcon - default is text only
            // fullScreen - at which breakpoint do we want to convert the modal to full width
            // to disable comment it out
            // "loginBtnVariant": "contained",
            // "fullScreen": "md",
            "data": {
                "setOpenDefault": true,
                "triggerText": "Reset Password",
                "loginTitle": "Reset Password",
                "loginSubtitle": "To complete the password reset process, please enter a new password.",
                // "loginUrl": "/signup",
                // "loginUrlLabel": "Reset Password",
                "showSocialLogin": false,
                "showTerms": true,
                "termsText": "By clicking “Reset Password” you agree to our",
                "termsLinkProps": {
                    "color": "inherit",
                    "target": "_blank",
                },
                "termsLink": "",
                "termsLabel": "Terms of Service",
                "privacyLink": "",
                "privacyLabel": "Privacy Policy",
                "formActionLabel": "Reset Password",
            }
        },
        "createPass": {
            "loginBtnType": "btnIcon", // btn, btnIcon, btnTextIcon - default is text only
            // fullScreen - at which breakpoint do we want to convert the modal to full width
            // to disable comment it out
            // "loginBtnVariant": "contained",
            // "fullScreen": "md",
            "data": {
                "setOpenDefault": true,
                "triggerText": "Create Password",
                "loginTitle": "Create Password",
                "loginSubtitle": "To complete the create password process, please enter a new password.",
                // "loginUrl": "/signup",
                // "loginUrlLabel": "Reset Password",
                "showSocialLogin": false,
                "showTerms": true,
                "termsText": "By clicking “Create Password” you agree to our",
                "termsLinkProps": {
                    "color": "inherit",
                    "target": "_blank",
                },
                "termsLink": "",
                "termsLabel": "Terms of Service",
                "privacyLink": "",
                "privacyLabel": "Privacy Policy",
                "formActionLabel": "Create Password",
            }
        },
        "unsubscribe": {
            "loginBtnType": "btnIcon", // btn, btnIcon, btnTextIcon - default is text only
            // fullScreen - at which breakpoint do we want to convert the modal to full width
            // to disable comment it out
            // "loginBtnVariant": "contained",
            // "fullScreen": "md",
            "dialogScroll": "body",
            "data": {
                "setOpenDefault": true,
                "triggerText": "Login",
                "loginTitle": "Unsubscribe from {source}",
                "loginSubtitle": "Unsubscribe will stop all {source} from being sent to you. Do you want to proceed",
                "successText": "You have successfully unsubscribed from {source}.",
                "loginUrl": "/login",
                "loginUrlLabel": "Login",
                "showSocialLogin": true,
                "resetPassLabel": "Forgot your password?",
                "resetPassUrl": "/forgot-password",
                "showTerms": true,
                "termsText": "By clicking “Continue” you agree to our",
                "termsLinkProps": {
                    "color": "inherit",
                    "target": "_blank",
                },
                "termsLink": "",
                "termsLabel": "Terms of Service",
                "privacyLink": "",
                "privacyLabel": "Privacy Policy",
                "formActionLabel": "Continue"
            },
        },
        "dialogAction": {
            "loginBtnType": "btnIcon", // btn, btnIcon, btnTextIcon - default is text only
            // fullScreen - at which breakpoint do we want to convert the modal to full width
            // to disable comment it out
            // "loginBtnVariant": "contained",
            // "fullScreen": "md",
            "dialogScroll": "body",
            "data": {
                "setOpenDefault": true,
                "triggerText": "Login",
                "loginTitle": "Confirmation Dialog",
                "loginSubtitle": "Unsubscribe will stop all {source} from being sent to you. Do you want to proceed",
                "successText": "You have successfully unsubscribed from {source}.",
                "loginUrl": "/login",
                "loginUrlLabel": "Login",
                "showSocialLogin": true,
                "resetPassLabel": "Forgot your password?",
                "resetPassUrl": "/forgot-password",
                "showTerms": true,
                "termsText": "By clicking “Continue” you agree to our",
                "termsLinkProps": {
                    "color": "inherit",
                    "target": "_blank",
                },
                "termsLink": "",
                "termsLabel": "Terms of Service",
                "privacyLink": "",
                "privacyLabel": "Privacy Policy",
                "formActionLabel": "Continue"
            },
        }
    },
    "sx": (theme) => ({
        "& .link-login": {
            "color": theme.palette.secondary.main,
            "fontWeight": "400"
        },
        "& .link-dialog": {
            "color": theme.palette.text.primary,
            "textDecoration": "underline"
        },
        "& .MuiDialog-container": {
            "transition": "none !important"
        },
        "& .backDrop": {
            "transition": "none !important",
            "backgroundImage": `url(${theme.loginBg})`,
            "backgroundRepeat": "no-repeat",
            "backgroundSize": "cover",
            "backgroundPosition": "center",
            "&:after": {
                "content": "''",
                "position": "absolute",
                "top": 0,
                "left": 0,
                "width": "100%",
                "height": "100%",
                "background": "linear-gradient(transparent, rgba(0,0,0,0.7))",
            },
        },
        "& .dialogTitle": {
            "textAlign": "center",
            "padding": theme.spacing(0, 2),
            [theme.breakpoints.up('sm')]: {
                "padding": theme.spacing(2,12,1,12),
            },
            "& .MuiTypography-root ": {
                "fontFamily": theme?.typography?.dialogTitle?.titleFont || theme?.primaryFontFamily,
                [theme.breakpoints.down('sm')]: {
                    "font-size": theme.spacing(2.6),
                }
            },
            // "padding": theme.spacing(2,4,1,4),
            // [theme.breakpoints.up('sm')]: {
            //     "padding": theme.spacing(9,12,1,12),
            // },
        },
        "& .dialogContent": {
            "padding": theme.spacing(0,4,1,4),
            [theme.breakpoints.up('md')]: {
                "padding": theme.spacing(0,12.5,1,12.5),
            },
            "& .dialogContentTitle": {
                "marginBottom": theme.spacing(4),
                "textAlign": "center",
                "color": theme.palette.grey.grey2,
                "& a": {
                    "fontFamily": theme.secondaryFontFamily,
                    "fontWeight": theme.typography.fontWeightBold,
                },
            },
            "& .dialogSocialButtonCont": {
                "margin": theme.spacing(0,-1.25,3,-1.25),
                "display": "flex",
                "flexDirection": "row",
                "flexWrap": "nowrap",
                [theme.breakpoints.up('md')]: {
                    "margin": theme.spacing(0,-1.625,3,-1.625),
                },
                "& .dialogSocialButton": {
                    "margin": theme.spacing(0,1.25),
                    "flexBasis": "0",
                    "flexGrow": "1",
                    "borderRadius": "100px",
                    [theme.breakpoints.up('md')]: {
                        "margin": theme.spacing(0,1.625),
                    },
                },
            },
            "& .dialogSeparator": {
                "position": "relative",
                "margin": theme.spacing(0,0,4,0),
                '&:before': {
                    "position": "absolute",
                    "content": '""',
                    "top": "50%",
                    "left": 0,
                    "marginTop": "3px",
                    "width": "100%",
                    "height": "1px",
                    "display": "block",
                    "backgroundColor": "#BDBDBD",
                },
            },
            "& .dialogSeparatorSpan": {
                "position": "relative",
                "display": "inline-block",
                "backgroundColor": theme.palette.background.paper,
                "padding": theme.spacing(0,3.5),
                "zIndex": "1",
                [theme.breakpoints.up('md')]: {
                    "padding": theme.spacing(0,6.75),
                },
            },
            "& .dialogFormControlElem": {
                "margin": theme.spacing(0,0,2,0),
            },
            "& .dialogTerms": {
                "textAlign": "left",
                "padding": theme.spacing(0,0,4,0),
                [theme.breakpoints.up('sm')]: {
                    "textAlign": "center",
                },
                [theme.breakpoints.up('md')]: {
                    "padding": theme.spacing(0,6.5,4,6.5),
                },
            },
            "& .dialogHelperText": {
                "textAlign": "left",
                "margin": 0,
                "padding": theme.spacing(0,0,2,0),
                // "color": theme.palette.primary.main,
                [theme.breakpoints.up('sm')]: {
                    "textAlign": "right",
                },
            },
            "& .dialogCheckbox": {
                "& span":{
                    "fontSize": "12px",
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": "14px"
                    },
                }
            },
            "& .dialogActions": {
                "width": "100%",
                "justify-content": "space-evenly",
                "padding": theme.spacing(0,0,4,0),
                [theme.breakpoints.up('md')]: {
                    "padding": theme.spacing(0,0,9.5,0),
                },
                "& .dialogActionsBtn": {
                    "width": "100%",
                },
            },
            "& .dialogActionsConfirm": {
                "width": "100%",
                "justify-content": "space-evenly",
                "padding": theme.spacing(0,0,4,0),
                [theme.breakpoints.up('md')]: {
                    "padding": theme.spacing(0,0,9.5,0),
                },
                "& .dialogActionsConfirmBtn": {
                    "width": "40%",
                },
            },
            "& .phonenumber_container": {
                // "background": 'red',
                "marginBottom": "20px",
                "& .PhoneInputInput": {
                    "width": "100%"
                },
                "& .PhoneInputCountryIcon": {
                    "width": "40px",
                    "position": "absolute",
                    "height": "40px",
                    "& img": {
                        "marginTop": "12px",
                        "paddingLeft": "10px",
                        "width": "100%"
                    }
                },
                "& .PhoneInputCountrySelect": {
                    "width": "40px",
                    "position": "absolute",
                    "opacity": "0",
                    "zIndex": "2",
                    "height": "40px",
                    "cursor": "pointer",
                },
                "& .MuiFormControl-root": {
                    "& input": {
                        "paddingLeft": "50px"
                    }
                }
            }
        },
        "& .dialogActionsConfirmContainer": {
            "display": "flex",
            "justify-content": "center",
            "margin-bottom": "3rem",
            "& .dialogActionsConfirm": {
                "width": "70%",
                "justify-content": "space-evenly",
                [theme.breakpoints.down('sm')]: {
                    "flex-direction": "column",
                    "width": "100%",
                    "padding": theme.spacing(0, 2),
                },
                "& .dialogActionsConfirmBtn": {
                    "width": "40%",
                    [theme.breakpoints.down('sm')]: {
                        "width": "100%",
                        "margin": theme.spacing(0, 0, 1),
                    },
                },
            }
        },
        "& .actionCardWrapper":{
            "padding": theme.spacing(0, 6),
            "margin": theme.spacing(2.6, 0),
            [theme.breakpoints.down('sm')]: {
                "padding": theme.spacing(0, 2),
            },
            "& .actionCard-card":{
                // "background": theme.palette.grey.grey3,
                "background": "#F6F6F5",
                "padding": theme.spacing(1, 3),
                "& .actionCard-cardHeader": {
                    [theme.breakpoints.down('sm')]: {
                        "flex-direction": "column",
                        "text-align": "center",
                    }
                },
                "& .cardHeader-avatar": {
                    [theme.breakpoints.down('sm')]: {
                        "display": "none",
                    }
                },
                "& .cardHeader-title": {
                    "fontSize": "16px",
                    "& .cardHeader-subheader": {
                        "fontSize": "14px"
                    }
                },
                "& .actionCard-cardActionButton": {
                    "background": "#fff"
                }

            }
        },
        "& .globalFormFeedback": {
            // "background": "blue",
            "padding": theme.spacing(1, 4, 6, 4),
            "margin": theme.spacing(2.6, 0),
            "maxWidth": "500px",
            "width": "100%",
            "margin": "auto",
            "& .globalForm-actionContainer": {
                "text-align": "center"
            },
            "& form": {
                // "background": theme.palette.grey.grey3,
                // "background": "#F6F6F5",
                "padding": theme.spacing(1),
                "& .globalForm-formButton": {
                    "width": "100%"
                }
            }
        },
        "& .MuiDialog-scrollPaper": {
            // "overflow": "auto",
            // "display": "block",
            // [theme.breakpoints.up('sm')]: {
            //     "display": "flex"
            // }
        },
        "& .MuiDialog-paperWidthAcionDialog-modal": {
            "maxWidth": "710px",
            "maxHeight": "none",
            "margin": "32px auto",
            "& .alertContainer": {
                "display": "flex",
                "flex-direction": "column",
                "align-items": "center",
                "margin-bottom": theme.spacing(6)
            },
            "& .alertContainerPlain": {
                "display": "flex",
                "flex-direction": "column",
                "align-items": "center",
                "margin-bottom": theme.spacing(6),
                "& .actionCardDialogChildren-alert": {
                    "paddingBottom": 0
                },
                "& .MuiAlert-standard, .MuiAlert-icon": {
                    "display": "block",
                    "textAlign": "center"
                },
                "& h3": {
                    "marginTop": 0,
                    "fontWeight": 400
                }
            },
            "& .actionCardDialogChildren-alert": {
                "width": "100%",
                "padding": theme.spacing(6),
                "& .MuiAlert-standard": {
                    "justifyContent": "center"
                }
            }
        },
        "& .MuiAlert-standard": {
            "marginBottom": theme.spacing(4)
        }
    })
}
